<template>
  <article id="site-main" class="site-main" role="main">
    <Loader v-if="isLoading" />
    <ErrorPage v-else-if="errorNotFound || errorServerError" />
    <div v-else class="page provider">
      <Breadcrumbs :breadcrumbs="providerBreadcrumbs" />
      <Hero :title="page.Title"></Hero>
      <div class="page__head container">
        <time
          v-if="page.LastChecked"
          :datetime="lastCheckedDate"
          class="page__date"
          >Last checked: {{ lastCheckedFormatted }}</time
        >
        <SocialSharing
          :title="page.Title"
          :description="page.MetaDescription"
          :url="url"
          heading="Share this"
          class="hide-sm-down"
        />
      </div>
      <div class="page__inner page__inner--left-sidebar container">
        <div class="page__content page__content--right">
          <div v-if="provider.Content" class="page__content-inner typography">
            <h2 class="h3">
              <icon
                name="cabicons-description"
                class="icon--heading"
                scale="1.25"
              />Description
            </h2>
            <div v-html="provider.Content"></div>
          </div>

          <div class="provider__details">
            <ContactDetails
              v-if="details.length || provider.ContactDetails.length"
              class="page__content-inner typography"
              :details="details"
              :contacts="provider.ContactDetails"
            />

            <ContactPerson
              v-for="(person, i) in contactPepole"
              :key="`person-${i}`"
              :person="person"
              class="page__content-inner typography"
            />
          </div>

          <Map
            v-if="provider.LatLng && !provider.HideMap"
            class="page__content-block hide-print"
            :center="provider.LatLng"
            :zoom="18"
            :interactive="false"
          >
            <template slot-scope="{ map }">
              <MapMarker :map="map" :feature="{ location: provider.LatLng }" />
            </template>
          </Map>

          <Feedback class="page__content-inner" :item_id="provider.ID" />

          <SocialSharing
            :title="page.Title"
            :description="page.MetaDescription"
            :url="url"
            heading="Share this"
            class="page__content-inner hide-sm-up"
          />
        </div>
        <aside
          class="page__sidebar page__sidebar--left provider__details--desktop"
        >
          <ContactDetails
            v-if="details.length || provider.ContactDetails.length"
            class="page__sidebar-inner typography"
            :details="details"
            :contacts="provider.ContactDetails"
          />

          <ContactPerson
            v-for="(person, i) in contactPepole"
            :key="`person-${i}`"
            :person="person"
            class="page__sidebar-inner typography"
          />
        </aside>
        <Map
          v-if="provider.LatLng && !provider.HideMap"
          class="show-print"
          :center="provider.LatLng"
          :zoom="18"
          :interactive="false"
        >
          <template slot-scope="{ map }">
            <MapMarker :map="map" :feature="{ location: provider.LatLng }" />
          </template>
        </Map>
      </div>
    </div>
  </article>
</template>

<script>
import get from "lodash/get";

import metaMixin from "@/mixins/meta";
import fetchPageMixin from "@/mixins/fetch-page";
import errorMixin from "@/mixins/error-page";

import Breadcrumbs from "@/components/Breadcrumbs";
import ContactDetails from "@/components/ServiceProvider/ContactDetails";
import ContactPerson from "@/components/ServiceProvider/ContactPerson";
import Feedback from "@/components/Feedback.vue";
import Hero from "@/components/Hero";
import Map from "@/components/Map/Map";
import MapMarker from "@/components/Map/MapMarker";
import SocialSharing from "@/components/SocialSharing";
import ErrorPage from "@/components/Error";

export default {
  name: "ServiceProvider",
  components: {
    Breadcrumbs,
    ContactDetails,
    ContactPerson,
    Feedback,
    Hero,
    Map,
    MapMarker,
    SocialSharing,
    ErrorPage,
  },

  mixins: [metaMixin, errorMixin, fetchPageMixin],

  created() {
    this.fetchArticleData();
  },

  data() {
    return {
      page: null,
      isError: false,
    };
  },

  computed: {
    providerBreadcrumbs() {
      return [
        { Title: "Community Directory", Url: "/search/?sp=1" },
        { Title: this.provider.Title },
      ];
    },

    provider() {
      return this.page;
    },

    metaTitle() {
      return get(this.page, "Title", "Loading content");
    },

    metaDescription() {
      return get(this.page, "Excerpt", "");
    },

    details() {
      const details = [];

      if (this.provider.OpeningHours) {
        details.push({
          title: "Opening Hours",
          content: this.provider.OpeningHours,
        });
      }

      if (this.provider.PhysicalAddress) {
        details.push({
          title: "Physical address",
          content: this.provider.PhysicalAddress,
        });
      }

      if (this.provider.MailingAddress) {
        details.push({
          title: "Mailing address",
          content: this.provider.MailingAddress,
        });
      }

      return details;
    },

    contactPepole() {
      return this.provider.Contacts.map((detail) => ({
        name: detail.Name,
        jobTitle: detail.JobTitle,
        contacts: detail.ContactDetails,
      }));
    },
  },
};
</script>

<style lang="scss">
.provider {
  &__details {
    margin-top: $block-spacing-mobile;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &--desktop {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}
</style>

<template>
  <article id="site-main" class="site-main" role="main">
    <Loader v-if="isLoading" />
    <ErrorPage v-else-if="errorNotFound || errorServerError" />

    <div v-else class="page">
      <Breadcrumbs
        :breadcrumbs="page.Breadcrumbs"
        v-if="page.Breadcrumbs && page.Breadcrumbs.length"
      />
      <Hero :title="page.Title" class="hero--white-bg"></Hero>

      <div 
        class="container page__inner"
        :class="{ 'nosidebar': page.HideSocialSharing }"
      >
        <div class="page__content">
          <div
            class="typography page__content-inner"
            :class="pageType"
            v-if="page.Content"
            v-html="page.Content"
            v-mobile-table
            v-alert
          />
          <div class="page__foot" v-if="!page.HideSocialSharing">
            <SocialSharing
              :title="page.Title"
              :description="page.MetaDescription"
              :url="url"
              class="page__foot-item hide-non-md"
            />
          </div>
        </div>
        <aside class="page__sidebar" v-if="!page.HideSocialSharing">
          <SocialSharing
            :title="page.Title"
            :description="page.MetaDescription"
            :url="url"
            class="page__sidebar-inner hide-md"
          />
        </aside>
      </div>
    </div>
  </article>
</template>

<script>
import metaMixin from "@/mixins/meta";
import fetchPageMixin from "@/mixins/fetch-page";
import errorMixin from "@/mixins/error-page";
import Breadcrumbs from "@/components/Breadcrumbs";
import Hero from "@/components/Hero";
import SocialSharing from "@/components/SocialSharing";
import get from "lodash/get";
import ErrorPage from "@/components/Error";

export default {
  name: "Page",

  components: {
    ErrorPage,
    Breadcrumbs,
    SocialSharing,
    Hero,
  },

  mixins: [metaMixin, errorMixin, fetchPageMixin],

  created() {
    this.fetchPageData();
  },

  computed: {
    metaTitle() {
      return get(this.page, "Title", "Loading content");
    },

    metaDescription() {
      return get(this.page, "MetaDescription", "");
    },

    pageType() {
      return get(this.page, "Type", "").toLowerCase();
    },
  },
};
</script>

<template>
  <article id="site-main" class="site-main" role="main">
    <Loader v-if="isLoading" />
    <ErrorPage v-else-if="errorNotFound || errorServerError" />

    <div v-else class="page">
      <Breadcrumbs :breadcrumbs="articleBreadcrumbs" />
      <Hero :title="page.Title"></Hero>

      <div class="container page__inner">
        <div class="page__content">
          <div
            class="typography page__content-inner"
            v-if="page.Content"
            v-html="page.Content"
            v-mobile-table
            v-alert
          />

          <Feedback
            class="page__content-inner"
            :item_id="page.ID"
            :show-feedback-note="false"
          />

          <div class="page__foot">
            <time
              :datetime="lastCheckedDate"
              class="page__date page__foot-item"
              v-if="page.LastChecked"
              >Last checked: {{ lastCheckedFormatted }}</time
            >
            <SocialSharing
              :title="page.Title"
              :description="page.MetaDescription"
              :url="url"
              class="page__foot-item hide-non-md"
            />
          </div>
        </div>
        <aside class="page__sidebar">
          <div v-if="displayCategory" class="h4 page__category">
            Read more on:
            <a class="page__category-link" :href="categoryLink">{{
              categoryName
            }}</a>
          </div>

          <SocialSharing
            :title="page.Title"
            :description="page.MetaDescription"
            :url="url"
            class="page__sidebar-inner hide-md"
          />
        </aside>
      </div>
    </div>
  </article>
</template>

<script>
import get from "lodash/get";

import metaMixin from "@/mixins/meta";
import fetchPageMixin from "@/mixins/fetch-page";
import errorMixin from "@/mixins/error-page";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Feedback from "@/components/Feedback.vue";
import Hero from "@/components/Hero";
import SocialSharing from "@/components/SocialSharing";
import Loader from "@/components/Loader";

import ErrorPage from "@/components/Error";

export default {
  name: "Article",

  components: {
    Breadcrumbs,
    ErrorPage,
    Loader,
    SocialSharing,
    Hero,
    Feedback,
  },

  mixins: [metaMixin, errorMixin, fetchPageMixin],

  computed: {
    metaTitle() {
      return get(this.page, "Title", "Loading content");
    },

    metaDescription() {
      return get(this.page, "Excerpt", "");
    },

    categoryLink() {
      return encodeURI(`/search/tag:"${this.categoryName}"`);
    },

    categoryName() {
      return get(this.page, "Tags[0].ParentCategory", "");
    },

    displayCategory() {
      return this.categoryName && this.$appSettings.BKDisplayCategory;
    },

    articleBreadcrumbs() {
      return [
        { Title: "Your Rights", Url: "/search/?yr=1" },
        { Title: this.page.Title },
      ];
    },
  },

  created() {
    this.fetchArticleData();
  },
};
</script>

<template>
  <div class="feedback">
    <div class="feedback__inner">
      <h2 class="h3 feedback__heading">
        <template v-if="!state.success">
          Did you find this information helpful?
        </template>
        <template v-else> Thank you for your feedback </template>
      </h2>
      <form
        class="feedback__form"
        @submit.prevent="submit"
        v-if="!state.success"
      >
        <div
          class="feedback__options"
          :class="{
            'feedback__options--open': isOpen,
          }"
        >
          <div class="flex flex--grid">
            <div class="column column-50">
              <button
                type="button"
                class="feedback__option feedback__option--helpful"
                :class="{
                  'feedback__option--active': state.helpful,
                }"
                tabindex="0"
                @click="toggleOption('helpful')"
                @key.enter="toggleOption('helpful')"
                :aria-selected="state.helpful"
              >
                <span class="feedback__option-check"
                  ><icon name="check" v-if="state.helpful"></icon
                ></span>
                <ThumbsUp
                  class="feedback__option-icon feedback__option-icon--helpful"
                />
                <span class="feedback__option-title">I found this useful</span>
              </button>
            </div>
            <div class="column column-50">
              <button
                type="button"
                class="feedback__option feedback__option--unhelpful"
                :class="{
                  'feedback__option--active': state.unhelpful,
                }"
                tabindex="0"
                @click="toggleOption('unhelpful')"
                @key.enter="toggleOption('unhelpful')"
                :aria-selected="state.unhelpful"
              >
                <span class="feedback__option-check"
                  ><icon name="check" v-if="state.unhelpful"></icon
                ></span>
                <ThumbsDown
                  class="feedback__option-icon feedback__option-icon--unhelpful"
                />
                <span class="feedback__option-title"
                  >I did not find this useful</span
                >
              </button>
            </div>
          </div>
        </div>

        <template v-if="isOpen">
          <div class="feedback__message">
            <textarea
              placeholder="Leave a comment"
              v-model="state.comment"
            ></textarea>
          </div>

          <p class="feedback__note" v-if="showFeedbackNote">
            Please note this feedback does not go to the organisation listed on
            this page, but to the CAB. If you want to contact this organisation
            please use the contact details on this page to contact them
            directly.
          </p>

          <button type="submit" class="btn">Tell us what you think</button>
        </template>
      </form>

      <transition name="fade">
        <div class="feedback__success" v-if="state.success">
          <div v-html="state.successMessage"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import icon from "vue-awesome";
import ThumbsUp from "@/assets/thumbs-up.svg?inline";
import ThumbsDown from "@/assets/thumbs-down.svg?inline";

export default {
  props: {
    item_id: {
      type: String,
      default: null,
    },
    page_id: {
      type: String,
      default: null,
    },
    showFeedbackNote: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    icon,
    ThumbsUp,
    ThumbsDown,
  },

  data() {
    return {
      state: {
        helpful: false,
        unhelpful: false,
        comment: null,
        error: null,
        success: false,
        successMessage: null,
      },
    };
  },

  computed: {
    isOpen() {
      return this.state.helpful || this.state.unhelpful;
    },
  },

  methods: {
    submit() {
      if (!this.state.helpful && !this.state.unhelpful && !this.state.comment) {
        this.state.error =
          "Please select one of the options or leave a comment.";
        return;
      }

      let data = {
        helpful: this.state.helpful,
        unhelpful: this.state.unhelpful,
        comment: this.state.comment,
      };

      if (this.item_id) {
        data.item = this.item_id;
      }

      if (this.page_id) {
        data.page = this.page_id;
      }

      this.$axios
        .post("/api/v1/feedback", data)
        .then((response) => {
          this.state.success = response.data.success;
          this.state.successMessage = response.data.message;
        })
        .catch((error) => {
          this.state.error = error.response.data.error;
        });
    },

    toggleOption(option) {
      this.state.error = null;

      switch (option) {
        case "unhelpful":
          this.state.unhelpful = true;
          this.state.helpful = false;
          break;

        case "helpful":
          this.state.helpful = true;
          this.state.unhelpful = false;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.feedback {
  transition: all 0.25s;

  @include media-breakpoint-up(xl) {
    padding: $base-spacing;
  }

  &__heading {
    margin-top: 0;
    margin-bottom: $block-heading-margin-bottom;
  }

  &__message {
    textarea {
      width: 100%;
      height: 140px;
      resize: none;
      color: $gray-darker;
      border: 1px solid $gray-darker;
      border-radius: 7px;
      background-color: #fff;
      box-shadow: none;
      margin-bottom: rem(8px);

      &:focus {
        outline: 0;
      }
    }
  }

  &__note {
    @include typography-element();
    margin-top: 0;
    margin-bottom: rem($base-spacing);
  }

  &__options {
    margin-bottom: rem(-16px);

    &--open {
      margin-bottom: rem(8px);
    }
  }

  &__option {
    border: 1px solid $gray-darker;
    border-radius: 7px;
    color: $gray-darker;
    width: 100%;
    height: 45px;
    padding: 10px 16px;
    background: none;
    text-align: left;
    position: relative;
    margin-bottom: rem(16px);
    cursor: pointer;
    display: flex;
    align-items: center;

    &-check {
      width: 24px;
      height: 24px;
      border: 1px solid #848484;
      border-radius: 3px;
      display: inline-block;
      margin-right: 7px;
      background-color: $gray-lighter;

      svg {
        position: relative;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        width: rem(12px);

        path {
          fill: var(--option-color);
        }
      }
    }

    &-icon {
      margin-right: 5px;
      &--unhelpful {
        margin-top: -3px;
      }
    }

    &:hover,
    &:focus {
      outline: 0;
      color: $color-body;

      & > .feedback__option-check {
        background-color: transparent;
      }
    }

    &--active {
      color: $color-body;
    }

    &--helpful {
      --option-color: #{$green-dark};
      --option-color-light: #{$green-lightest};
    }

    &--unhelpful {
      --option-color: #{$red-dark};
      --option-color-light: #{$red-lightest};
    }

    &:hover,
    &:focus {
      border-color: var(--option-color);
    }

    &.feedback__option--active {
      border-color: var(--option-color);

      &:hover,
      &:focus {
        & > .feedback__option-check {
          background-color: var(--option-color-light);
        }
      }

      .feedback__option-check {
        background-color: var(--option-color-light);
        border-color: var(--option-color);
      }
    }
  }
}

@media print {
  .feedback {
    display: none;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import ServiceProvider from "../views/ServiceProvider.vue";
import Page from "../views/Page.vue";
import SearchResult from "../views/SearchResult.vue";
import Article from "../views/Article.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/search/:query?/:page?",
    name: "search",
    component: SearchResult,
  },
  {
    path: "/article/:id",
    name: "article",
    component: Article,
  },
  {
    path: "/community-directory/:id",
    name: "community-directory",
    component: ServiceProvider,
  },
  {
    path: "*",
    name: "page",
    component: Page,
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

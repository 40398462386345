import Loader from "@/components/Loader";

export default {
  components: {
    Loader,
  },

  data() {
    return {
      isLoading: true,
      page: null,
      isError: false,
      status: 200,
    };
  },

  methods: {
    fetchPage() {
      if (this.$route.query && this.$route.query.stage) {
        let paths = this.$route.path.replace(/\/$/, "").split("/");
        let urlSegment = paths[paths.length - 1] || "home";
        let url = `/api/v1/page/${urlSegment}?SubsiteID=${this.$appSettings.Subsite}`;

        if (this.$route.query.stage === "Stage") {
          url += "&stage=Stage";
        }

        return this.$axios.get(url);
      }

      return this.$axios.get(
        `/assets/data/pages/${this.$appSettings.Subsite}/${
          this.$route.path.replace(/^\/|\/$/g, "") || "home"
        }.json`
      );
    },

    fetchArticle() {
      if (this.$loggedIn > 0) {
        return this.$axios.get(`/api/v1/item/${this.$route.params.id}`);
      }
      return this.$axios.get(`/assets/data/${this.$route.params.id}.json`);
    },

    fetchPageData() {
      this.fetchPage()
        .then((response) => {
          this.page = response.data;
          this.$emit("updateHead");
          this.status = 200;
        })
        .catch(() => {
          this.isError = true;
          this.status = 404;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    fetchArticleData() {
      // Fetch article data
      this.fetchArticle()
        .then((response) => {
          this.page = response.data;
          this.$emit("updateHead");
          this.status = 200;
        })
        .catch(() => {
          this.isError = true;
          this.status = 404;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
